import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useContextSelector } from 'use-context-selector'

import Button, { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { TemplatesListSchemaFragment, EmployeePreferencesTab } from 'gql/__generated__/graphql'
import { BlockMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { TemplatePickerContext, useLoadAllIds } from '../hooks'
import { TemplatePickerMode } from '../types'
import s from './TemplatePickerFooter.module.scss'

interface ISectionPickerProps {
  onSubmit: (result: { selected: string[]; template?: TemplatesListSchemaFragment }) => void
  clearSelect: () => void
  setSelected: Dispatch<
    SetStateAction<{
      multi: string[]
      [BlockMode.cover]: string | null
      [BlockMode.start]: string | null
      [BlockMode.end]: string | null
    }>
  >
  templates: TemplatesListSchemaFragment[]
  tab: EmployeePreferencesTab
  hideAddAll?: boolean
  disabled?: boolean
}

const TemplatePickerFooter: React.FC<ISectionPickerProps> = ({
  onSubmit,
  templates,
  clearSelect,
  setSelected,
  tab,
  hideAddAll,
  disabled,
}) => {
  const {
    mode,
    selected,
    step,
    setStep,
    collectionId,
    allSelected,
    blocksByMode,
    search,
    tag,
    companyId,
    type,
    projectId,
  } = useContextSelector(TemplatePickerContext, (value) => value)

  const [allIdsLoading, setAllIdsLoading] = useState(false)

  const vars = useMemo(
    () => ({
      search,
      tag,
      editorTemplateGroupId: collectionId,
      companyId,
      type,
      projectId,
      tab,
    }),
    [search, tag, collectionId, companyId, type, projectId, step, tab],
  )

  const loadAllIds = useLoadAllIds(vars)
  const selectAll = async () => {
    setAllIdsLoading(true)
    const data = await loadAllIds()
    const templatesAllIds = data?.data.data || []
    setSelected((prev) => ({ ...prev, multi: templatesAllIds }))
    setAllIdsLoading(false)
  }

  const titleSelected =
    tab === EmployeePreferencesTab.archived
      ? t('modal.pickerTemplate.blocks.selected')
      : t('modal.pickerTemplate.templates.selected')

  const nextStep = () => {
    setStep((prev) => (prev !== null ? prev + 1 : null))
  }
  const tabHasStep =
    (tab === EmployeePreferencesTab.collections && !!collectionId) ||
    tab !== EmployeePreferencesTab.collections

  const next = (step === 0 || step === 1) && mode !== TemplatePickerMode.change && tabHasStep

  const onClick = () => {
    const template = templates.find((template) => template.uuid === allSelected[0])
    onSubmit({ template, selected: allSelected })
  }

  return (
    <div className={s.modalFooter}>
      <div className={s.leftSide}>
        {mode === TemplatePickerMode.multi && !hideAddAll && (
          <Button
            disabled={!templates.length || Boolean(selected.multi.length) || disabled}
            loading={allIdsLoading}
            name='selectAll'
            onClick={selectAll}
            styleType='ghost'
          >
            {t('uiKit.button.chooseAll')}
          </Button>
        )}
        <div className={s.close}>
          {Boolean(selected.multi.length) && (
            <div className={s.actionsContainer}>
              {mode === TemplatePickerMode.multi && (
                <div className={s.selected}>
                  <IconButton
                    icon='otherClose'
                    name='close'
                    onClick={clearSelect}
                    size={KitSize.S}
                    styleType='ghost'
                  />
                  <span className={s.selectedTitle}>
                    {titleSelected}
                    {selected.multi.length}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={s.buttons}>
        <Button
          disabled={!allSelected.length}
          name={mode === TemplatePickerMode.change ? 'change' : 'add'}
          onClick={onClick}
          styleType={next && !blocksByMode?.end ? 'secondary' : 'primary'}
        >
          {mode === TemplatePickerMode.change ? t('uiKit.button.replace') : t('uiKit.button.add')}
        </Button>
        {next && !blocksByMode?.end && (
          <Button name='next' onClick={nextStep}>
            {t('uiKit.button.next')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default TemplatePickerFooter
