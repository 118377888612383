// import * as R from 'ramda'
import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useProjectContext } from 'services/Store/Project/hooks'
import { getDeviceMode } from 'services/Store/Project/selectors'

import s from './AbsolutePortal.module.scss'
import AbsolutePortalContext from './AbsolutePortalContext'
import { BoxType } from './types'

interface IAbsolutePortalProviderProps {
  children: React.ReactNode
  viewport?: React.RefObject<HTMLDivElement>
}

const AbsolutePortalProvider: React.FC<IAbsolutePortalProviderProps> = ({ children, viewport }) => {
  const deviceMode = useProjectContext(getDeviceMode)
  const portalsRef = useRef<HTMLDivElement>(null)
  const portals = useRef<Record<string, BoxType | null>>({})
  const setPortals = useCallback(
    (
      p:
        | Record<string, BoxType | null>
        | ((prev: Record<string, BoxType | null>) => Record<string, BoxType | null>),
    ) => {
      if (typeof p === 'function') {
        portals.current = p(portals.current)
      } else {
        portals.current = p
      }
    },
    [],
  )
  useEffect(() => {
    portals.current = {}
  }, [deviceMode])

  const value = useMemo(
    () => ({ viewport, portals, setPortals, portalsRef }),
    [setPortals, viewport],
  )

  return (
    <AbsolutePortalContext.Provider value={value}>
      {children}
      <div className={cn(s.root, s.resetBaseFont)} ref={portalsRef} />
      {/* {R.toPairs(portals.current).map(([key, box]) => {
        if (!box) {
          return null
        }
        return (
          <div
            key={key}
            style={{
              position: 'absolute',
              top: box.y,
              left: box.x,
              width: box.width,
              height: box.height,
              pointerEvents: 'none',
              zIndex: 100000,
              backgroundColor: 'red',
              opacity: 0.1,
            }}
          />
        )
      })} */}
    </AbsolutePortalContext.Provider>
  )
}

export default React.memo(AbsolutePortalProvider)
