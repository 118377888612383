import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { useLocation } from 'react-router'
import { dateFormatTable } from 'utils'

import { Tooltip } from 'components/uiKit/Dropdown'
import Employee, { EmployeeById } from 'components/uiKit/Employee'
import Link from 'components/uiKit/Link'
import { FileComponent, FolderComponent } from 'components/uiKit/Table'
import { ColumnsType } from 'components/uiKit/Table/types'
import generateLink from 'routes/generateLink'
import { useLink, usePathParams } from 'routes/hooks'
import { selectEmployees } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { t } from 'services/Translation'
import { FinderItem } from 'store/models'
import { extendTestData } from 'utils/test/qaData'
import { isFinderFile } from 'utils/useFinder'

import { Paths } from '../types'

interface IProps {
  record: FinderItem
  clearQuery: () => void
  paths: Paths
}

const NameComponent: React.FC<IProps> = ({ record, paths, clearQuery }) => {
  const { search } = useLocation()
  const params = usePathParams(paths.main)
  const linkToFolder = generateLink(
    paths.main,
    { ...params, fileMetaGroupId: record.id },
    { search },
  )
  const linkToFile = useLink(paths.preview, { ...params, fileId: record.id })
  if (isFinderFile(record)) {
    return (
      <Tooltip mouseEnterDelay={1} overlay={record?.name} trigger={['hoverWithoutContent']}>
        <Link
          name={`linkToFile${record.id}`}
          testData={extendTestData({
            id: record.id,
            createdBy: record.createdBy?.id,
            name: record.name,
          })}
          to={linkToFile}
        >
          <FileComponent file={record} />
        </Link>
      </Tooltip>
    )
  }

  return (
    <Tooltip mouseEnterDelay={1} overlay={record?.name} trigger={['hoverWithoutContent']}>
      <Link
        name={record.id}
        onClick={clearQuery}
        testData={extendTestData({
          id: record.id,
          createdBy: record.createdBy?.id,
        })}
        to={linkToFolder}
      >
        <FolderComponent folder={record} />
      </Link>
    </Tooltip>
  )
}

const NameComponentModal: React.FC<{
  record: FinderItem
  onClickItem: (item: FinderItem) => void
}> = ({ record, onClickItem }) => {
  return (
    <Tooltip mouseEnterDelay={1} overlay={record?.name} trigger={['hoverWithoutContent']}>
      <div onClick={() => onClickItem(record)}>
        {isFinderFile(record) ? (
          <FileComponent file={record} />
        ) : (
          <FolderComponent folder={record} />
        )}
      </div>
    </Tooltip>
  )
}

// TODO: change type on BE
export const CreatedBy: React.FC<{ createdBy: any }> = ({ createdBy }) => {
  const employees = useAppSelector(selectEmployees)
  if (createdBy?.__typename === 'Employee') {
    return <EmployeeById id={createdBy.id} styleType='default' />
  } else if (createdBy?.__typename === 'KUser') {
    const employee = employees.find((e) => e.kUser.id === createdBy.id)
    if (!employee) {
      return null
    }
    return <Employee employee={employee} styleType='default' />
  }
  return null
}

export const COLUMN_BASE_CONFIG: ColumnsType<FinderItem> = [
  {
    title: t('table.title.format'),
    sortKey: 'ext',
    dataIndex: ['files', '0', 'ext'],
    width: 111,
    render: (ext) => (ext ? ext.toUpperCase() : t('common.folder')),
  },
  {
    title: t('table.title.size'),
    sortKey: 'size',
    dataIndex: 'size',
    align: 'right',
    width: 100,
    render: (size) => <span>{numeral(size).format('0.0 b')}</span>,
  },
  {
    title: t('common.createdBy'),
    sortKey: 'createdBy',
    dataIndex: 'createdBy',
    width: 184,
    render: (createdBy) => <CreatedBy createdBy={createdBy} />,
  },
  {
    title: t('table.title.createDate'),
    dataIndex: 'createdAt',
    sortKey: 'createdAt',
    width: 120,
    render: (createdAt) => moment(createdAt).format(dateFormatTable),
  },
]

export const columnsConfig = (paths: Paths, clearQuery: () => void): ColumnsType<FinderItem> => [
  {
    title: t('table.title.title'),
    sortKey: 'name',
    dataIndex: 'name',
    width: 476,
    render: (_text, record) => (
      <NameComponent clearQuery={clearQuery} paths={paths} record={record} />
    ),
  },
  ...COLUMN_BASE_CONFIG,
]

export const columnsConfigModal = (
  onClickItem: (item: FinderItem) => void,
): ColumnsType<FinderItem> => [
  {
    title: t('table.title.title'),
    sortKey: 'name',
    dataIndex: 'name',
    width: 476,
    render: (_text, record) => <NameComponentModal onClickItem={onClickItem} record={record} />,
  },
  ...COLUMN_BASE_CONFIG,
]
