import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import Icon from 'components/uiKit/Icon'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

import s from './Loader.module.scss'
import SvgLoader from './SvgLoader'

interface ILoader {
  name: string
  styleType?: 'default' | 'absolute' | 'fixed' | 'svg' | 'inline'
  color?: 'primary' | 'background'
  resources?: boolean
}

const COLOR_MAP = {
  primary: 'var(--color-primary-default)',
  background: 'var(--color-background-main)',
}

const RESOURCES = [
  t('uiKit.loader.text1'),
  t('uiKit.loader.text2'),
  t('uiKit.loader.text3'),
  t('uiKit.loader.text4'),
  t('uiKit.loader.text5'),
  t('uiKit.loader.text6'),
  t('uiKit.loader.text7'),
  t('uiKit.loader.text8'),
  t('uiKit.loader.text9'),
  t('uiKit.loader.text10'),
  t('uiKit.loader.text11'),
  t('uiKit.loader.text12'),
  t('uiKit.loader.text13'),
  t('uiKit.loader.text14'),
  t('uiKit.loader.text15'),
  t('uiKit.loader.text16'),
  t('uiKit.loader.text17'),
]

const useFakeResourceBuilderProgress = () => {
  const [currentResource, setCurrentResource] = useState(RESOURCES[0])

  useEffect(() => {
    let interval: NodeJS.Timeout
    const doJob = () => {
      clearInterval(interval)
      setCurrentResource(RESOURCES[Math.floor(Math.random() * RESOURCES.length)])
      interval = setInterval(doJob, Math.floor(Math.random() * 500) + 300)
    }

    doJob()

    return () => clearInterval(interval)
  }, [RESOURCES])

  return currentResource
}

const Loader = React.forwardRef<HTMLDivElement, ILoader>(
  ({ name, resources, styleType = 'default', color = 'primary' }, ref) => {
    if (styleType === 'svg') {
      return <SvgLoader color={COLOR_MAP[color]} />
    }
    const cnRoot = cn(s.root, s[styleType])
    return (
      <div {...testProps({ el: 'loader', name })} className={cnRoot} ref={ref}>
        <div className={s.spinner}>
          <Icon name='spinner' style={{ color: COLOR_MAP[color] }} />
        </div>
        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
        {resources && <div className={s.resources}>{useFakeResourceBuilderProgress()}</div>}
      </div>
    )
  },
)

Loader.displayName = 'Loader'

export default Loader
