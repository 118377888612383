import { DeepPartial } from '@leenda/editor/lib/utils/types'
import { produce } from 'immer'
import lodash from 'lodash'
import { useState, useCallback, useMemo } from 'react'

import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'

import { IFieldMeta } from '../Field/Field.types'
import { DEFAULT_FORM_ERRORS } from './helpers'
import { isCommonError, isInputError } from './typeGuards'
import { AsyncFieldError, IFormMeta, inputErrorValuesTypes } from './types'

interface IUpdateValue<T> {
  name?: string
  value: T
}

type UseFormValues<T> = [DeepPartial<T | unknown>, (update: IUpdateValue<T>) => void]

export const useFormValues = <T>(initialValues: DeepPartial<T | unknown>): UseFormValues<T> => {
  const [values, setValues] = useState<DeepPartial<T | unknown>>(initialValues || {})

  const updateValue = useCallback(({ name, value }: IUpdateValue<T>) => {
    setValues((prevValues: any) =>
      produce(prevValues, (draft: Record<string, unknown>) => {
        if (name) {
          return lodash.set(draft, name, value)
        } else {
          return value
        }
      }),
    )
  }, [])

  return [values, updateValue]
}

type MetaState = Record<string, IFieldMeta>
type UpdateMeta = { name?: string; meta: IFieldMeta }

type UseFormMeta = [MetaState, ({ name, meta }: UpdateMeta) => void]

export const useFormMeta = (initialMeta: MetaState = {}): UseFormMeta => {
  const [meta, setMeta] = useState<MetaState>(initialMeta)

  const updateMeta = ({ name, meta: newMeta }: UpdateMeta) => {
    setMeta((prevMeta) => {
      if (name) {
        return produce(prevMeta, (draft) => {
          if (name) {
            draft[name] = newMeta

            if (newMeta.commonError) {
              for (const field in draft) {
                draft[field].commonError = false
              }
            }
          }
        })
      } else if (newMeta) {
        return newMeta as typeof prevMeta
      }
      return prevMeta
    })
  }

  return [meta, updateMeta]
}

type UseSubmitForm<R> = [submit: () => Promise<R | void | null>, submitting: boolean]

export const useSubmitForm = <
  T,
  R extends { data?: { errors?: (AsyncFieldError | null)[] | null; data?: any } },
>({
  onSubmit,
  formMeta,
  fullValues,
  values,
  metas,
  resetForm,
  onChangeMeta,
  inputErrorValues,
  setMetas,
}: {
  onSubmit?: (
    fullValues: T,
    values: DeepPartial<T>,
    meta: IFormMeta,
  ) => Promise<R | void | null> | void
  formMeta: IFormMeta
  fullValues: T
  values: DeepPartial<T>
  metas: Record<string, any>
  resetForm: () => void
  onChangeMeta: (name?: string, meta?: IFieldMeta) => void
  inputErrorValues?: inputErrorValuesTypes
  setMetas: ({ name, meta }: UpdateMeta) => void
}): UseSubmitForm<R> => {
  const [submitting, setSubmitting] = useState<boolean>(false)

  const dictionaryErrors = useMemo<inputErrorValuesTypes>(
    () => ({ ...DEFAULT_FORM_ERRORS, ...inputErrorValues }),
    [inputErrorValues],
  )

  const submit = useCallback(async () => {
    setSubmitting(true)

    if (!formMeta.valid || !onSubmit) {
      console.warn('FORM:submit- not valid', formMeta, metas)
      setSubmitting(false)
      return
    }

    try {
      const res = await onSubmit(fullValues, values, formMeta)
      const errors = (res && res.data?.errors) || []
      if (res?.data?.data) {
        resetForm()
      }
      if (errors.length) {
        errors.forEach((error) => {
          const errorReason = error?.reason || ''
          const dictionaryError = dictionaryErrors[errorReason]
          const textError =
            typeof dictionaryError === 'function'
              ? dictionaryError(error?.value || '')
              : dictionaryError
          if (isInputError(error)) {
            onChangeMeta(error.field || '', {
              ...metas[error.field || ''],
              error: textError,
              dirty: true,
              touched: true,
              valid: false,
            })
          }

          if (isCommonError(error)) {
            error.fields?.forEach((field) => {
              setMetas({
                name: field,
                meta: {
                  ...metas[field],
                  commonError: true,
                  touched: true,
                },
              })
            })
            notify({
              message: textError || error.reason || '',
              type: NotificationType.error,
            })
          }
        })
        return
      }
      return res
    } catch (error) {
      console.error('common.formError', error)
    } finally {
      setSubmitting(false)
    }
  }, [onSubmit, values, fullValues, metas, formMeta])

  return [submit, submitting]
}
