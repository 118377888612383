import { SlateElementType } from '@leenda/rich-text'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  getSelectedFormat,
  getSetToolbarForm,
  getUpdateFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import TextArea from 'components/uiKit/TextArea'
import { stopPropagation } from 'constants/commonConstans'
import { t } from 'services/Translation'

import { useToolbarPosition } from '../../useToolbarPosition'
import s from './AnnotationForm.module.scss'

const AnnotationForm: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLTextAreaElement | null>(null)
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const [value, setValue] = useState<string>(selectedFormat.inline?.value || '')
  const setToolbarForm = useRichTextContext(getSetToolbarForm)
  const onUpdateFormat = useRichTextContext(getUpdateFormat)

  const handleClose = useCallback(() => setToolbarForm('default'), [setToolbarForm])

  const onSubmit = () => {
    onUpdateFormat(SlateElementType.annotation, value)
    handleClose()
  }

  const onDelete = () => {
    onUpdateFormat(SlateElementType.annotation, null)
    handleClose()
  }

  useEffect(() => {
    setValue(selectedFormat.inline?.value)
  }, [selectedFormat.inline?.value])

  useToolbarPosition(ref)

  // stopPropagation - HOTFIX for https://www.notion.so/2413-1885457f94c5812498f2e5ff0fc8f373
  return (
    <div className={s.root} onClick={stopPropagation} ref={ref}>
      <IconButton
        icon='builderInputBack'
        name='cancel'
        onClick={handleClose}
        size={KitSize.S}
        styleType='secondary'
      />
      <TextArea
        autoSize={{ minRows: 1, maxRows: 16 }}
        lineHeight={18}
        name='annotation'
        onChange={setValue}
        placeholder={t('input.placeholder.addAnnotation')}
        ref={textRef}
        size='mini'
        styleType='ghost'
        value={value}
        autoFocus
        fluid
      />
      <div className={s.buttons}>
        {selectedFormat.inline?.value && (
          <IconButton
            icon='otherBrokenLink'
            name='delete'
            onClick={onDelete}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
        <IconButton
          disabled={!value}
          icon={value ? 'iconSend' : 'iconSendDisabled'}
          name='save'
          onClick={onSubmit}
          size={KitSize.S}
          styleType='ghost'
        />
      </div>
    </div>
  )
}

export default AnnotationForm
