import { HeaderSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import React from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'

import BurgerStyled from '../../styled/BurgerStyled'
import LineStyled from '../../styled/LineStyled'
import s from './MenuOpenIcon.module.scss'

interface IMenuOpenIconProps {
  toggleSidebar: () => void
  isDisabledSidebarAndHome?: boolean
  styles: ElementStyleCss<HeaderSchemaType>
  isFloatingBurger?: boolean
  positionRight?: boolean
  isOpen?: boolean
}

const ANIMATION_VARIANTS = {
  open: {
    top: {
      top: 13,
      rotate: 45,
      transition: { top: { duration: 0.3 }, rotate: { duration: 0.3, delay: 0.3 } },
    },
    middle: { top: 13, opacity: 0, transition: { opacity: { duration: 0.3 } } },
    bottom: {
      width: 15,
      top: 13,
      rotate: -45,
      transition: { top: { duration: 0.3 }, rotate: { duration: 0.3, delay: 0.3 } },
    },
  },
  closed: {
    top: {
      top: 8,
      rotate: 0,
      transition: { top: { duration: 0.3, delay: 0.3 }, rotate: { duration: 0.3 } },
    },
    middle: { top: 13, opacity: 1, transition: { opacity: { duration: 0.3, delay: 0.3 } } },
    bottom: {
      width: 8,
      top: 18,
      rotate: 0,
      transition: { top: { duration: 0.3, delay: 0.3 }, rotate: { duration: 0.3 } },
    },
  },
}

const MenuOpenIcon: React.FC<IMenuOpenIconProps> = ({
  toggleSidebar,
  isDisabledSidebarAndHome: disabled,
  styles,
  isFloatingBurger,
  positionRight,
  isOpen,
}) => {
  const pt = useProjectT()
  const burgerIconBg = styles.burgerIcon.backgroundImage
  const closeIconBg = styles.crossIcon.backgroundImage
  const showCustomIcon = (burgerIconBg && !isOpen) || (closeIconBg && isOpen)

  return (
    <div
      className={cn(s.root, {
        [s.floatingButton]: isFloatingBurger,
        [s.positionRight]: positionRight,
        [s.disabled]: disabled,
      })}
      onClick={() => !disabled && toggleSidebar()}
      style={isFloatingBurger ? styles.effects : {}}
    >
      <Tooltip disabled={disabled} overlay={pt('header.courseContent')}>
        {showCustomIcon ? (
          <div
            className={s.customMenu}
            style={{ backgroundImage: isOpen ? closeIconBg : burgerIconBg }}
          />
        ) : (
          <BurgerStyled $disabled={disabled} $styles={styles}>
            <LineStyled
              $disabled={disabled}
              $styles={styles}
              animate={isOpen ? ANIMATION_VARIANTS.open.top : ANIMATION_VARIANTS.closed.top}
              initial={false}
            />
            <LineStyled
              $disabled={disabled}
              $styles={styles}
              animate={isOpen ? ANIMATION_VARIANTS.open.middle : ANIMATION_VARIANTS.closed.middle}
              initial={false}
            />
            <LineStyled
              $disabled={disabled}
              $styles={styles}
              animate={isOpen ? ANIMATION_VARIANTS.open.bottom : ANIMATION_VARIANTS.closed.bottom}
              initial={false}
            />
          </BurgerStyled>
        )}
      </Tooltip>
    </div>
  )
}

export default MenuOpenIcon
